import styles from './LangNav.module.scss';

import iconDownload from '../../assets/icons/icon-download.svg';
import iconPlayground from '../../assets/icons/icon-playground.svg';
import imgGithub from '../../assets/github.svg';
import { useTranslation } from 'react-i18next';

const LangNav = ({
  selectedFE,
  selectedBE,
  setSelectedFE,
  setSelectedBE,
}: {
  selectedFE: string;
  selectedBE: string;
  setSelectedFE: Function;
  setSelectedBE: Function;
}) => {
  const { t } = useTranslation();

  const getPlaygroundURL = (): string => {
    if (selectedBE !== 'node') {
      return '';
    }

    switch (selectedFE) {
      case 'html': {
        return 'https://codesandbox.io/s/smartpay-example-html-and-node-yukqs';
      }
      case 'vanillajs': {
        return 'https://codesandbox.io/s/smartpay-example-html-js-and-node-ew894';
      }
      case 'react': {
        return 'https://codesandbox.io/s/smartpay-example-react-and-node-z6l9l';
      }
      default: {
        return '';
      }
    }
  };

  const playgroundURL = getPlaygroundURL();

  return (
    <div className={styles.langPickerWrapper}>
      <div className={styles.langPicker}>
        <div className={styles.langs}>
          <div className={styles.langGroup}>
            <h6>{t('lang-group-frontend')}</h6>
            <nav>
              <button
                type="button"
                className={selectedFE === 'html' ? styles.active : ''}
                onClick={() => setSelectedFE('html')}
              >
                HTML
              </button>
              <button
                type="button"
                className={selectedFE === 'vanillajs' ? styles.active : ''}
                onClick={() => setSelectedFE('vanillajs')}
              >
                Vanilla JS
              </button>
              <button
                type="button"
                className={selectedFE === 'react' ? styles.active : ''}
                onClick={() => setSelectedFE('react')}
              >
                React
              </button>
            </nav>
          </div>
          <div className={styles.langGroup}>
            <h6>{t('lang-group-backend')}</h6>
            <nav>
              <button
                type="button"
                className={selectedBE === 'node' ? styles.active : ''}
                onClick={() => setSelectedBE('node')}
              >
                Node
              </button>
              {/* <button disabled>PHP</button> */}
              <button
                type="button"
                className={selectedBE === 'python' ? styles.active : ''}
                onClick={() => setSelectedBE('python')}
              >
                Python
              </button>
              <button
                type="button"
                className={selectedBE === 'ruby' ? styles.active : ''}
                onClick={() => setSelectedBE('ruby')}
              >
                Ruby
              </button>
              <button
                type="button"
                className={selectedBE === 'php' ? styles.active : ''}
                onClick={() => setSelectedBE('php')}
              >
                PHP
              </button>
              <button
                type="button"
                className={selectedBE === 'go' ? styles.active : ''}
                onClick={() => setSelectedBE('go')}
              >
                Go
              </button>
            </nav>
          </div>
        </div>
        <nav className={styles.external}>
          <a
            href={`/examples/${selectedFE}-and-${selectedBE}.zip`}
            download={`${selectedFE}-and-${selectedBE}.zip`}
            title="Download the working example"
          >
            <img src={iconDownload} alt="Download App" width={24} />
            Download
          </a>
          {playgroundURL && (
            <a
              href={playgroundURL}
              target="_blank"
              rel="noopener noreferrer"
              title="Playground at CodeSandbox"
            >
              <img src={iconPlayground} alt="Playground" width={24} />
              Playground
            </a>
          )}
          <a
            href={`https://github.com/smartpay-co/integration-examples/tree/main/examples/${selectedFE}-and-${selectedBE}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Clone from Github"
          >
            <img src={imgGithub} height={24} width={24} alt="Github" />
          </a>
        </nav>
      </div>
    </div>
  );
};

export default LangNav;
