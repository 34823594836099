import raw from 'raw.macro';

export interface CodeMeta {
  language: string;
  fileName: string;
  code: string;
  installationScript?: string;
  installationScriptAlternative?: string;
  depFile?: string;
}

export type Step =
  | 'SET_UP_SERVER'
  | 'CREATE_SESSION'
  | 'DEFINE_LINEITEMS'
  | 'CALLBACK_URLS'
  | 'LAUNCH_CHECKOUT'
  | 'CHECKOUT_BUTTON';

interface Highlight {
  codeFile: string;
  lines: string;
}

interface CodeMetas {
  fe: CodeMeta;
  be: CodeMeta;
  html: CodeMeta;
  highlights: { [key: string]: Highlight | null };
}

export type CodeFileType = keyof CodeMetas;
const BE_CODES = {
  node: raw('../../../examples/server/node/server.js'),
  'node-with-html': raw('../../../examples/server/node-with-html/server.js'),
  python: raw('../../../examples/server/python/server.py'),
  'python-with-html': raw(
    '../../../examples/server/python-with-html/server.py'
  ),
  ruby: raw('../../../examples/server/ruby/server.rb'),
  'ruby-with-html': raw('../../../examples/server/ruby-with-html/server.rb'),
  php: raw('../../../examples/server/php/server.php'),
  'php-with-html': raw('../../../examples/server/php-with-html/server.php'),
  go: raw('../../../examples/server/go/main.go'),
  'go-with-html': raw('../../../examples/server/go-with-html/main.go'),
};

const getCodeMeta = (fe: string, be: string) => {
  const meta: CodeMetas = {
    fe: {
      language: 'javascript',
      fileName: '',
      code: '',
      depFile: '',
    },
    be: {
      language: '',
      fileName: '',
      code: '',
      installationScript: 'yarn add @smartpay/sdk-node',
      installationScriptAlternative: 'npm install --save @smartpay/sdk-node',
      depFile: '',
    },
    html: { language: 'html', fileName: '', code: '' },
    highlights: {
      SET_UP_SERVER: null,
      CREATE_SESSION: null,
      DEFINE_LINEITEMS: null,
      CALLBACK_URLS: null,
      LAUNCH_CHECKOUT: null,
      CHECKOUT_BUTTON: null,
    },
  };

  switch (fe) {
    case 'html': {
      meta.html = {
        language: 'html',
        fileName: 'index.html',
        code: raw('../../../examples/client/html/build/index.html'),
      };

      meta.highlights.CHECKOUT_BUTTON = {
        codeFile: 'html',
        lines: '7-9',
      };

      break;
    }
    case 'vanillajs': {
      meta.fe = {
        language: 'javascript',
        fileName: 'client.js',
        code: raw('../../../examples/client/vanillajs/src/client.js'),
        depFile: 'package.json',
      };
      meta.html = {
        language: 'html',
        fileName: 'index.html',
        code: raw('../../../examples/client/vanillajs/build/index.html'),
      };

      meta.highlights.DEFINE_LINEITEMS = {
        codeFile: 'fe',
        lines: '5-12',
      };
      meta.highlights.CALLBACK_URLS = {
        codeFile: 'fe',
        lines: '48-49',
      };
      meta.highlights.LAUNCH_CHECKOUT = {
        codeFile: 'fe',
        lines: '61-63',
      };
      meta.highlights.CHECKOUT_BUTTON = {
        codeFile: 'html',
        lines: '8',
      };

      break;
    }
    case 'react':
    default: {
      meta.fe = {
        language: 'jsx',
        fileName: 'App.js',
        code: raw('../../../examples/client/react/src/App.js'),
        depFile: 'package.json',
      };

      meta.highlights.DEFINE_LINEITEMS = {
        codeFile: 'fe',
        lines: '9-16',
      };
      meta.highlights.CALLBACK_URLS = {
        codeFile: 'fe',
        lines: '52-53',
      };
      meta.highlights.LAUNCH_CHECKOUT = {
        codeFile: 'fe',
        lines: '65-67',
      };
      meta.highlights.CHECKOUT_BUTTON = {
        codeFile: 'fe',
        lines: '73',
      };
      break;
    }
  }

  const withHTML = fe === 'html' ? '-with-html' : '';

  switch (be) {
    case 'python': {
      meta.be = {
        language: 'python',
        fileName: 'server.py',
        code: BE_CODES[withHTML ? 'python-with-html' : 'python'],
        installationScript: 'pip install smartpay',
        depFile: 'requirements.txt',
      };

      meta.highlights.SET_UP_SERVER = {
        codeFile: 'be',
        lines: '4',
      };
      meta.highlights.CREATE_SESSION = {
        codeFile: 'be',
        lines: withHTML ? '28-71' : '23-31',
      };

      if (withHTML) {
        meta.highlights.DEFINE_LINEITEMS = {
          codeFile: 'be',
          lines: '31-38',
        };
        meta.highlights.CALLBACK_URLS = {
          codeFile: 'be',
          lines: '69-70',
        };
        meta.highlights.LAUNCH_CHECKOUT = {
          codeFile: 'be',
          lines: '73',
        };
      }

      break;
    }
    case 'ruby': {
      meta.be = {
        language: 'ruby',
        fileName: 'server.rb',
        code: BE_CODES[withHTML ? 'ruby-with-html' : 'ruby'],
        installationScript: 'gem install smartpay',
        depFile: 'Gemfile',
      };

      meta.highlights.SET_UP_SERVER = {
        codeFile: 'be',
        lines: withHTML ? '2' : '3',
      };
      meta.highlights.CREATE_SESSION = {
        codeFile: 'be',
        lines: withHTML ? '20-66' : '15-26',
      };

      if (withHTML) {
        meta.highlights.DEFINE_LINEITEMS = {
          codeFile: 'be',
          lines: '23-30',
        };
        meta.highlights.CALLBACK_URLS = {
          codeFile: 'be',
          lines: '64-65',
        };
        meta.highlights.LAUNCH_CHECKOUT = {
          codeFile: 'be',
          lines: '68',
        };
      }

      break;
    }
    case 'php': {
      meta.be = {
        language: 'php',
        fileName: 'server.php',
        code: BE_CODES[withHTML ? 'php-with-html' : 'php'],
        installationScript:
          'composer require smartpay-co/sdk-php && composer install',
        depFile: 'composer.json',
      };

      meta.highlights.SET_UP_SERVER = {
        codeFile: 'be',
        lines: withHTML ? '3' : '3',
      };
      meta.highlights.CREATE_SESSION = {
        codeFile: 'be',
        lines: withHTML ? '13-57' : '23',
      };

      if (withHTML) {
        meta.highlights.DEFINE_LINEITEMS = {
          codeFile: 'be',
          lines: '16-21',
        };
        meta.highlights.CALLBACK_URLS = {
          codeFile: 'be',
          lines: '55-56',
        };
        meta.highlights.LAUNCH_CHECKOUT = {
          codeFile: 'be',
          lines: '64',
        };
      }

      break;
    }
    case 'go': {
      meta.be = {
        language: 'go',
        fileName: 'main.go',
        code: BE_CODES[withHTML ? 'go-with-html' : 'go'],
        installationScript: 'go get github.com/smartpay-co/sdk-go',
        depFile: 'go.mod',
      };

      meta.highlights.SET_UP_SERVER = {
        codeFile: 'be',
        lines: withHTML ? '5' : '7',
      };
      meta.highlights.CREATE_SESSION = {
        codeFile: 'be',
        lines: withHTML ? '19-36' : '30',
      };

      if (withHTML) {
        meta.highlights.DEFINE_LINEITEMS = {
          codeFile: 'be',
          lines: '22-24',
        };
        meta.highlights.CALLBACK_URLS = {
          codeFile: 'be',
          lines: '32-33',
        };
        meta.highlights.LAUNCH_CHECKOUT = {
          codeFile: 'be',
          lines: '41',
        };
      }

      break;
    }
    case 'node':
    default: {
      meta.be = {
        language: 'javascript',
        fileName: 'server.js',
        code: BE_CODES[withHTML ? 'node-with-html' : 'node'],
        installationScript: 'yarn add @smartpay/sdk-node',
        installationScriptAlternative: 'npm install --save @smartpay/sdk-node',
        depFile: 'package.json',
      };

      meta.highlights.SET_UP_SERVER = {
        codeFile: 'be',
        lines: '5',
      };
      meta.highlights.CREATE_SESSION = {
        codeFile: 'be',
        lines: withHTML ? '30-80' : '26-30',
      };

      if (withHTML) {
        meta.highlights.DEFINE_LINEITEMS = {
          codeFile: 'be',
          lines: '33-40',
        };
        meta.highlights.CALLBACK_URLS = {
          codeFile: 'be',
          lines: '76-77',
        };
        meta.highlights.LAUNCH_CHECKOUT = {
          codeFile: 'be',
          lines: '82',
        };
      }

      break;
    }
  }

  return meta;
};

export default getCodeMeta;
