import { useState, useLayoutEffect, useEffect } from 'react';
import { useI18next, Link } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import cx from 'classnames';

import Layout from '../components/Layout/Layout';
import CodeBox from '../components/CodeBox/CodeBox';
import LangNav from '../components/IntegrationScreen/LangNav';

import getCodeMeta, {
  CodeFileType,
  CodeMeta,
  Step,
} from '../components/IntegrationScreen/code-meta';

import styles from '../styles/pages/index.module.scss';

const DEFAULT_CODE_FILE: CodeFileType = 'html';

export const integrationPageQuery = graphql`
  query IntegrationPageQuery($language: String!) {
    site {
      siteMetadata {
        name
        tagline
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const IntegrationBuilder = () => {
  const { t, originalPath, i18n } = useI18next();
  const [codeHighlight, setCodeHighlight] = useState('');
  const [activeCodeFile, setActiveCodeFile] = useState<CodeFileType>('be');
  const [selectedFE, setSelectedFE] = useState(DEFAULT_CODE_FILE);
  const [selectedBE, setSelectedBE] = useState('node');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [articleClassName, setArticleClassName] = useState('');
  const [step, setStep] = useState('');

  const codeMeta = getCodeMeta(selectedFE, selectedBE);
  const activeCodeMeta = codeMeta[activeCodeFile] as CodeMeta;

  if (!activeCodeMeta || !activeCodeMeta.fileName) {
    setActiveCodeFile(
      codeMeta[DEFAULT_CODE_FILE] && codeMeta[DEFAULT_CODE_FILE].fileName
        ? DEFAULT_CODE_FILE
        : 'fe'
    );
  }

  useLayoutEffect(() => {
    const [from] = codeHighlight.split('-');
    const parentEl = document.querySelector('#inner_code > div > pre');

    if (from && parentEl) {
      const targetEl = document.querySelector(
        `#inner_code > div > pre > code > span:nth-child(${from})`
      );

      if (targetEl && targetEl instanceof HTMLElement) {
        parentEl.scrollTop = targetEl.offsetTop - 22 * 2;
      }
    }
  }, [codeHighlight, activeCodeFile]);

  useEffect(() => {
    if (!step) {
      setCodeHighlight('');

      return;
    }

    const highlight = codeMeta.highlights[step];

    if (!highlight) {
      return;
    }

    setActiveCodeFile(highlight.codeFile as CodeFileType);
    setCodeHighlight(highlight.lines);
  }, [step, codeMeta]);

  const onArticleClick = (nextStep: Step) => () => {
    setStep(step === nextStep ? '' : nextStep);
  };

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      SEOProps={{ title: 'Get Started' }}
    >
      <LangNav
        selectedFE={selectedFE}
        selectedBE={selectedBE}
        setSelectedFE={setSelectedFE}
        setSelectedBE={setSelectedBE}
      />
      <div className={styles.mainWrapper}>
        <main className={styles.main}>
          <section className={styles.guide}>
            <div className={styles.content}>
              <h1 style={{ paddingTop: 0 }}>{t(`integration-title`)}</h1>
              <p>{t(`integration-desc`)}</p>
              <h3>{t(`integration-prerequisites-title`)}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`integration-prerequisites-desc-1`),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`integration-prerequisites-desc-2`),
                }}
              ></p>
              <h2>1. {t(`integration-step-1-title`)}</h2>
              <article onClick={onArticleClick('SET_UP_SERVER')}>
                <h3>{t(`integration-step-1-atricle-1-title`)}</h3>
                <p>
                  {t(`integration-step-1-atricle-1-desc-1`)}
                  <em>{codeMeta.be.depFile}</em>
                  {t(`integration-step-1-atricle-1-desc-2`)}
                  <a href={`/examples/${selectedFE}-and-${selectedBE}.zip`}>
                    {t(`integration-step-1-atricle-1-desc-3`)}
                  </a>
                </p>
                {codeMeta.be.installationScript && (
                  <CodeBox
                    text={codeMeta.be.installationScript}
                    language="shell"
                    showLineNumbers={false}
                  />
                )}
                {codeMeta.be.installationScriptAlternative && (
                  <>
                    {t(`integration-step-1-atricle-1-desc-4`)}
                    <CodeBox
                      text={codeMeta.be.installationScriptAlternative}
                      language="shell"
                      showLineNumbers={false}
                    />
                  </>
                )}
              </article>
              <article onClick={onArticleClick('CREATE_SESSION')}>
                <h3>{t(`integration-step-1-atricle-2-title`)}</h3>
                <p>{t(`integration-step-1-atricle-2-desc`)}</p>
              </article>
              <article onClick={onArticleClick('DEFINE_LINEITEMS')}>
                <h3>{t(`integration-step-1-atricle-3-title`)}</h3>
                <p>{t(`integration-step-1-atricle-3-desc`)}</p>
              </article>
              <article onClick={onArticleClick('CALLBACK_URLS')}>
                <h3>{t(`integration-step-1-atricle-4-title`)}</h3>
                <p>{t(`integration-step-1-atricle-4-desc`)}</p>
              </article>
              <article onClick={onArticleClick('LAUNCH_CHECKOUT')}>
                <h3>{t(`integration-step-1-atricle-5-title`)}</h3>
                <p>{t(`integration-step-1-atricle-5-desc`)}</p>
              </article>
              <h2>2. {t(`integration-step-2-title`)}</h2>
              {/* <article>
                <h3>Add an order preview page</h3>
                <p>
                  Add a page to show a preview of the customer's order. Allow
                  the customer to review or modify their order—once a customer
                  is sent to the Checkout page, the order is final and cannot be
                  modified without creating a new Checkout Session.
                </p>
              </article> */}
              <article onClick={onArticleClick('CHECKOUT_BUTTON')}>
                <h3>{t(`integration-step-2-atricle-1-title`)}</h3>
                <p>{t(`integration-step-2-atricle-1-desc`)}</p>
              </article>
              <h2>3. {t(`integration-step-3-title`)}</h2>
              <article className={cx(articleClassName, styles.static)}>
                <h3>{t(`test-account-title`)}</h3>
                <p>{t(`test-account-desc-1`)}</p>
                <p
                  dangerouslySetInnerHTML={{ __html: t(`test-account-desc-2`) }}
                ></p>
                <p>{t(`test-account-desc-3`)}</p>
                <ul
                  dangerouslySetInnerHTML={{ __html: t(`test-account-desc-4`) }}
                ></ul>
              </article>
              <article className={styles.static}>
                <h3>{t(`integration-step-3-atricle-2-title`)}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(`integration-step-3-atricle-2-desc`),
                  }}
                ></p>
              </article>
              <h2>{t(`integration-step-4-title`)}</h2>
              <p>{t(`integration-step-4-desc-1`)}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`integration-step-4-desc-2`),
                }}
              ></p>
            </div>
          </section>
          <section className={styles.code} id="code">
            <nav className={styles.files}>
              {codeMeta.be.fileName && (
                <button
                  type="button"
                  className={activeCodeFile === 'be' ? styles.active : ''}
                  onClick={() => {
                    if (activeCodeFile !== 'be') {
                      setActiveCodeFile('be');
                      setCodeHighlight('');
                    }
                  }}
                >
                  {codeMeta.be.fileName}
                </button>
              )}
              {codeMeta.fe.fileName && (
                <button
                  type="button"
                  className={activeCodeFile === 'fe' ? styles.active : ''}
                  onClick={() => {
                    if (activeCodeFile !== 'fe') {
                      setActiveCodeFile('fe');
                      setCodeHighlight('');
                    }
                  }}
                >
                  {codeMeta.fe.fileName}
                </button>
              )}
              {codeMeta.html.fileName && (
                <button
                  type="button"
                  className={activeCodeFile === 'html' ? styles.active : ''}
                  onClick={() => {
                    if (activeCodeFile !== 'html') {
                      setActiveCodeFile('html');
                      setCodeHighlight('');
                    }
                  }}
                >
                  {codeMeta.html.fileName}
                </button>
              )}
            </nav>
            <div id="inner_code" className={styles.innerCode}>
              {activeCodeMeta && (
                <CodeBox
                  text={activeCodeMeta.code}
                  language={activeCodeMeta.language}
                  highlight={codeHighlight}
                />
              )}
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default IntegrationBuilder;
